<template>
  <v-app >
    <cabecera/>
    <v-main style="background: #eef5f9"
    >
      <v-container    >
        <v-row class="text-center">
          <v-col cols="12" lg="8" sm="12" md="8">
          <v-row>
            <v-col cols="12" lg="12" sm="12" md="12">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="4" sm="4" md="4">
                      <v-img height="50px" :src="persona.foto"></v-img>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">{{persona.nombre}}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" lg="12" sm="12" md="12">
              <v-card>
            <v-card-title>Datos Personales</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="12" sm="12" md="12">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Nombre</p>
                      <p style="text-align: left; font-size: 12pt;">{{persona.nombre}}</p>
                    </v-col>
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Apellido Paterno</p>
                      <p style="text-align: left; font-size: 12pt;">{{persona.apellidoPaterno}}</p>
                    </v-col>
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Apellido Materno</p>
                      <p style="text-align: left; font-size: 12pt;">{{persona.apellidoMaterno}}</p>
                    </v-col>
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Telefono</p>
                      <p style="text-align: left; font-size: 12pt;">{{persona.telefono}}</p>
                    </v-col>
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">DNI</p>
                      <p style="text-align: left; font-size: 12pt;">{{persona.dni}}</p>
                    </v-col>
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Correo</p>
                      <p style="text-align: left; font-size: 12pt;">{{persona.email}}</p>
                    </v-col>
                    <v-col cols="12" lg="6" sm="6" md="6">
                      <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Sexo</p>
                      <p style="text-align: left; font-size: 12pt;">{{persona.sexo.sexo}}</p>
                    </v-col>

                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="primary" @click="cambiaDatos">Editar</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          </v-col>
          <v-col cols="12" lg="4" sm="12" md="4">
          <v-row>
            <v-col cols="12" lg="12" sm="12" md="12">
              <v-card>
              <v-card-title>Imagen DNI</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" lg="12" sm="12" md="12">
              <v-card>
<v-card-title>DATOS DE UBICACION</v-card-title>
                <v-card-text>
<v-row>
  <v-col cols="12" lg="12" sm="12" md="12">
    <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Direccion</p>
    <p style="text-align: left; font-size: 12pt;">{{persona.direccion}}</p>
  </v-col>
  <v-col cols="12" lg="12" sm="12" md="12">
    <p style="text-align: left; font-weight: bolder; font-size: 15pt;">Distrito</p>
    <p style="text-align: left; font-size: 12pt;">{{persona.distrito.distrito}}</p>
  </v-col>
</v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="primary" @click="cambiaDire">Editar</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          </v-col>
        </v-row>
      </v-container></v-main>
    <piepagina/>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
    <datopersonal />
    <datoubicacion />
  </v-app>
</template>

<script>

import cabecera from './general/cabecera'
import piepagina from './general/piepagina';
import moment from "moment-timezone";
import {mapGetters} from "vuex";
import store from "@/store";
import Datopersonal from "@/components/modals/datopersonal";
import Datoubicacion from "@/components/modals/datoubicacion";

export default {
  name: 'perfil',
  components: {
    Datoubicacion,
    Datopersonal,
    cabecera,
    piepagina,

  },
  data ()  {
    return {
      tickets:[],
      overlay:false,
      persona:{}
    }
  },
  mounted() {
    if(this.usuario.id>0){
      this.persona= this.usuario
    }else{
      this.$router.push('/');
    }
  },
  methods:{
    formatDatee(value) {
      return moment(value).locale('es-pe').format("DD MMMM YYYY")
    },

    cambiaDatos(){
      store.dispatch("showDatosPersonales")
    },
    cambiaDire(){
      store.dispatch("showDatosUbicacion")
    }

  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario",
      showDatosPersonales : "showDatosPersonales",
      showChangePassword : "showChangePassword",
      showDatosUbicacion : "showDatosUbicacion"
    })
  }
}
</script>
