<template>
  <v-dialog
      v-model="showDatosUbicacion"
      persistent
      width="unset"
  >
    <v-card>
      <v-card-title>Datos Personales</v-card-title>
      <v-card-text>
        <v-form  ref="form">
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="persona.direccion"
                  label="Direccion"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" lg="12" md="12">
              <v-autocomplete placeholder="Distrito" v-model="persona.distrito" return-object item-text="distrito" label="Distrito" :items="distritos">

              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="error" @click="cerrar">Cerrar</v-btn>  <v-btn class="primary" @click="save">Editar</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
  </v-dialog>
</template>

<script>

import {mapGetters} from "vuex";
import store from "@/store";
import axios from "axios";
import moment from "moment-timezone";

export default {
  name: 'datoubicacion',
  data: () => ({
    overlay:false,
    persona: {},
    distritos:[],

  }),
  mounted() {
    this.persona = this.usuario
    this.dameDistritos()
  },
  methods:{
    cerrar(){
      store.dispatch("hideDatosUbicacion")
    },
    save(){
      var bodyFormData = new FormData();
      bodyFormData.append("data",JSON.stringify(this.persona));
      this.persona.fechaNacimiento = this.formatDarete(new Date(this.persona.fechaNacimiento+" 00:00:00"))
      console.log(this.persona.fechaNacimiento)
      axios
          .post('personas/save',bodyFormData)
          .catch((error)  => {
            console.error(error);
          });
      store.dispatch("hideDatosUbicacion")
      store.dispatch("fetchUsuario",this.persona)
    },
    dameDistritos(){
      this.overlay = true
      let urlfiesta = 'distritos/ciudad/129' ;
      axios
          .get(urlfiesta)
          .then(response => {
            this.distritos = response.data;
            this.overlay = false
          })
          .catch((error)  => {
            console.log(error);
          });
    },
    formatDarete (date) {
      var fec = moment(date).locale('es-pe');
      console.log(fec);
      return fec.tz("America/Lima").format("DD-MM-YYYY");
    },
    formatDate (date) {
      return moment(date).locale('es-pe').format("YYYY-MM-DD");
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario",
      showDatosUbicacion: "showDatosUbicacion"
    })
  }
}
</script>