<template>
  <v-dialog
      v-model="showDatosPersonales"
      persistent
      width="unset"
  >
    <v-card>
      <v-card-title>Datos Personales</v-card-title>
      <v-card-text>
        <v-form  ref="form">
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-text-field
                v-model="persona.dni"
                label="DNI"
                required
            ></v-text-field>
          </v-col><v-col cols="12" lg="12" md="12" sm="12">
          <v-text-field
              v-model="persona.nombre"
              label="Nombre"
              required
          ></v-text-field>
        </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-text-field
                v-model="persona.apellidoPaterno"
                label="Apellido Paterno"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-text-field
                v-model="persona.apellidoMaterno"
                label="Apellido Materno"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-text-field
                v-model="persona.telefono"
                label="Celular"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-autocomplete
            v-model="persona.sexo"
            return-object
            :items="sexos"
            item-value="id"
            item-text="sexo"
            >

            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="persona.fechaNacimiento" label="Fecha de Nacimiento"
                              hint="YYYY-MM-DD" persistent-hint
                              @blur="date = persona.fechaNacimiento"
                              v-on="on"
                              prepend-icon="fas fa-calendar" ></v-text-field></template>
              <v-date-picker
                  v-model="date"
                  no-title
                  min="1950-01-01"
                  @input="menu1 = false"
                  @change="persona.fechaNacimiento = date"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="12" lg="12" md="12">
            <v-autocomplete
                v-model="usuario.paisNac"
                :items="paises"
                return-object
                label="Pais de Nacimiento"
                placeholder="Pais de Nacimiento"
                prepend-icon="fas fa-flag"
                item-text="pais"
                @change="dameTiposDocumentos"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"

                >
                  <v-avatar left>
                    <v-img :src="require(`@/assets/flags/gc_country_flag_${data.item.codigo}.svg`)"></v-img>
                  </v-avatar>
                  {{ data.item.pais }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <img :src="require(`@/assets/flags/gc_country_flag_${data.item.codigo}.svg`)">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.pais"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

          </v-col>
        </v-row></v-form>
      </v-card-text>
      <v-card-actions>
      <v-btn class="error" @click="cerrar">Cerrar</v-btn>  <v-btn class="primary" @click="save">Editar</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
  </v-dialog>
</template>
<script>

import {mapGetters} from "vuex";
import store from "@/store";
import moment from "moment-timezone";
import axios from 'axios';

export default {
  name: 'datopersonal',
  data: () => ({
    overlay:false,
    persona: {},
    tiposdocumento: [],
    date: new Date().toISOString().substr(0, 10),
    menu1:false,
  }),
  mounted() {
    this.persona = this.usuario
    this.getPaises()
    this.dameTiposDocumentos()
  },
  watch: {
    date () {
      this.persona.fechaNacimiento = this.formatDate(this.date)
    },
  },
  methods:{
    cerrar(){
      store.dispatch("hideDatosPersonales")
    },
    save(){
      var bodyFormData = new FormData();
      bodyFormData.append("data",JSON.stringify(this.persona));
      this.persona.fechaNacimiento = this.formatDarete(new Date(this.persona.fechaNacimiento+" 00:00:00"))
      console.log(this.persona.fechaNacimiento)
      axios
      .post('personas/save',bodyFormData)
          .catch((error)  => {
            console.error(error);
          });
      store.dispatch("hideDatosPersonales")
      store.dispatch("fetchUsuario",this.persona)
    },
    formatDarete (date) {
      var fec = moment(date).locale('es-pe');
      console.log(fec);
      return fec.tz("America/Lima").format("DD-MM-YYYY");
    },
    formatDate (date) {
      return moment(date).locale('es-pe').format("YYYY-MM-DD");
    },
    getPaises(){
      axios.get("paises/activos")
          .then(response=>{
            this.paises = response.data
          })
    },

    dameTiposDocumentos(){
      this.progr = true;
      this.overlay = true
      let urlfiesta = 'tiposdocumento/pais/' + this.persona.paisNac.id ;
      axios
          .get(urlfiesta)
          .then(response => {
            this.tiposdocumento = response.data;
            this.overlay = false
          })
          .catch((error)  => {
            console.error(error);
          });
    },

  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario",
      sexos: "sexos",
      showDatosPersonales: "showDatosPersonales"
    })
  }
}
</script>